@font-face {
  font-family : 'Equip';
  src         : url('../../public/fonts/EquipThi.woff') format('woff');
  font-weight : 300;
  font-style  : normal;
  font-display: swap;
}

@font-face {
  font-family : 'Equip';
  src         : url('../../public/fonts/EquipLig.woff') format('woff');
  font-weight : 400;
  font-style  : normal;
  font-display: swap;
}


@font-face {
  font-family : 'Equip';
  src         : url('../../public/fonts/EquipMed.woff') format('woff');
  font-weight : 500;
  font-style  : normal;
  font-display: swap;
}

@font-face {
  font-family : 'Equip-Extended';
  src         : url('../../public/fonts/EquipExtMed.woff') format('woff');
  /* src      : url('../../public/fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/Roboto-Regular.woff2') format('woff2'),
    url('../public/fonts/Roboto-Regular.woff') format('woff'),
    url('../public/fonts/Roboto-Regular.ttf') format('truetype'); */
  font-weight : 500;
  font-style  : normal;
  font-display: swap;
}


@font-face {
  font-family: 'Equip-Extended';
  src: url('../../public/fonts/EquipExtReg.woff') format('woff');
  font-weight : 400;
  font-style  : normal;
  font-display: swap;
}


@font-face {
  font-family: 'Inter';
  src        : url('../../public/fonts/Inter-Regular.woff') format('woff');
  font-weight : 400;
  font-style  : normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src        : url('../../public/fonts/Inter-Medium.woff') format('woff');
  font-weight : 500;
  font-style  : normal;
  font-display: swap;
}