.loader {
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.loader-innner-text {
  top: 33%;
  left: 13%;
}

.tooltip-right{
  background-color: red;
}

.loadingdots-parent div {
  position: absolute;
  top: 33px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #13294B;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loadingdots-parent div:nth-child(1) {
  left: 8px;
  animation: loadingdots-parent1 0.6s infinite;
}
.loadingdots-parent div:nth-child(2) {
  left: 8px;
  animation: loadingdots-parent2 0.6s infinite;
}
.loadingdots-parent div:nth-child(3) {
  left: 32px;
  animation: loadingdots-parent2 0.6s infinite;
}
.loadingdots-parent div:nth-child(4) {
  left: 56px;
  animation: loadingdots-parent3 0.6s infinite;
}

@keyframes loadingdots-parent1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loadingdots-parent3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loadingdots-parent2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
}
