@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

body {
  background-color: #E8ECEF;
  font-size       : 16px;
}


.App-header {
  background-color: #282c34;
  min-height      : 100vh;
  display         : flex;
  flex-direction  : column;
  align-items     : center;
  justify-content : center;
  font-size       : calc(10px + 2vmin);
  color           : white;
}

.App-link {
  color: #61dafb;
}

.main-container {
  height: calc(100% - 70px);
}

.main-section {
  width: calc(100% - 13rem);
}

.dashboard-container {
  height: 100vh;
}


.available-list::-webkit-scrollbar {
  width: 10px;
}


.available-list::-webkit-scrollbar-track {
  border-radius   : 5px;
  background-color: #EAEFF3;
}

.available-list::-webkit-scrollbar-thumb {
  background-color: #13294B;
  border-radius   : 5px;
}

.loading-dots span {
  animation: dots 2s ease-out infinite;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.4s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.6s;
}

.animateModal {
  animation: modalAnimator .300s ease-in-out;
}

.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px 0px, rgba(0, 0, 0, 0.24) 0px 1px 2px 0px;
}

.display-on-pfd {
  display: none;
}

.table-container {
  page-break-inside: auto
}

.table-body-row {
  page-break-inside: avoid;
  page-break-after : auto
}

.table-body-text-style {
  white-space  : nowrap;
  overflow     : hidden;
  text-overflow: ellipsis;
}

/*ResponsiveContainer does not resize before printing */
/* https://github.com/recharts/recharts/issues/1114
.recharts-wrapper,
    .recharts-surface{
      @media print {
        width: calc(100%)!important;
      }
    }
    */

/*ResponsiveContainer does not resize before printing */
/*https://github.com/recharts/recharts/issues/1114*/
.recharts-wrapper,
    .recharts-surface{
      @media print {
        width: calc(100%)!important;
      }
    }

@keyframes modalAnimator {
  0% {
    opacity: 0.2;
  }

  25% {
    opacity: 0.4;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 0.8;
  }
}

@keyframes dots {
  50% {
    opacity  : 0;
    transform: translateY(5px);
  }
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {

  html,
  body {
    height                       : initial !important;
    overflow                     : initial !important;
    print-color-adjust           : exact;
    -webkit-print-color-adjust   : exact;
    /* zoom                      : 90%; */
  }

  /* on pdf download, hide element */
  .hide-print {
    display: none;
  }

  .table-body-text-style {
    white-space: unset
  }

  .display-on-pfd {
    display: block;
  }

  .pdf-page-title {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size  : '28px';
    font-weight: '400';
    color      : '#13294B';
  }

  .page-break {
    margin-top       : 1rem;
    display          : block;
    page-break-before: auto;
  }

  @page {
    size  : A4 landscape;
    margin: 4rem 1rem;
  }

  /* //Recalculate width in print mode to fix the bug the graph isn't responsive when print. */
  .recharts-wrapper,
  .recharts-surface {
    width: 100% !important;
  }

  /* //Prevent recharts-legend-item have space between <svg> icon and <span> due to recalculate width: 100% */
  /* //try width: fit-content not work on safari */
  .recharts-legend-item>svg {
    width  : auto !important;
    display: inline-block !important;
    right  : inherit !important;
    /* align to left */
  }
}